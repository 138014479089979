<template>
  <el-dialog
    append-to-body
    :title="title"
    :visible.sync="dialogFormVisible"
    width="400px"
    @close="close"
  >
    <el-form ref="form" label-width="120px" :model="form" :rules="rules">
      <vab-query-form>
        <vab-query-form-top-panel>
          <el-form-item
            label="进口国家:"
            prop="importCountry"
            style="width: 300px"
          >
            <el-input
              v-model.trim="form.importCountry"
              maxlength="200"
              show-word-limit
            />
          </el-form-item>
          <el-form-item
            label="进口海关编码:"
            prop="importHsCode"
            style="width: 300px"
          >
            <el-input
              v-model.trim="form.importHsCode"
              maxlength="200"
              show-word-limit
            />
          </el-form-item>
          <el-form-item
            label="关税税率:"
            prop="rateOfDuty"
            style="width: 300px"
          >
            <el-input-number
              v-model="form.rateOfDuty"
              :max="100"
              :min="0"
              :precision="2"
              size="medium"
              :step="0.1"
              style="width: 180px"
            />
          </el-form-item>
          <el-form-item label="风险评估:" prop="riskType" style="width: 300px">
            <el-select
              v-model="form.riskType"
              clearable
              placeholder="请选择类型"
            >
              <el-option
                v-for="item in riskTypeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            label="是否可承接:"
            prop="acceptability"
            style="width: 300px"
          >
            <el-select
              v-model="form.acceptability"
              clearable
              placeholder="请选择类型"
            >
              <el-option
                v-for="item in acceptabilityList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item
            label="包税附加费:"
            prop="surcharge"
            style="width: 300px"
          >
            <el-input-number
              v-model="form.surcharge"
              :max="100"
              :min="0"
              :precision="2"
              size="medium"
              :step="0.1"
              style="width: 180px"
            />
          </el-form-item>
          <el-form-item
            label="认证要求:"
            prop="certification"
            style="width: 300px"
          >
            <el-input
              v-model.trim="form.certification"
              maxlength="200"
              show-word-limit
            />
          </el-form-item>
        </vab-query-form-top-panel>
      </vab-query-form>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import {
    editCustomerMappingDetails,
    saveCustomerMappingDetails,
  } from '@/api/cmsApi'
  export default {
    props: {
      fileId: Number,
      Modulelist: Function,
    },
    data() {
      return {
        riskTypeList: [
          { id: 1, name: '低' },
          { id: 2, name: '中' },
          { id: 3, name: '高' },
        ],
        acceptabilityList: [
          { id: 1, name: '是' },
          { id: 2, name: '否' },
        ],
        title: '',
        dialogFormVisible: false,
        form: {
          id: '',
          fileId: '',
          importCountry: '',
          importHsCode: '',
          rateOfDuty: 0,
          riskType: '',
          acceptability: '',
          surcharge: '',
          certification: '',
        },
        rules: {
          importCountry: [
            { required: true, trigger: 'blur', message: '请输入进口国家' },
          ],
        },
      }
    },
    mounted() {},
    methods: {
      async showEdit(row) {
        this.form.fileId = this.fileId
        if (!row.id) {
          this.title = '新增映射明细'
        } else {
          this.title = '编辑映射明细'
          const form = {
            id: row.id,
            fileId: row.fileId,
            importCountry: row.importCountry,
            importHsCode: row.importHsCode,
            rateOfDuty: row.rateOfDuty,
            riskType: row.riskType,
            acceptability: row.acceptability,
            surcharge: row.surcharge,
            certification: row.certification,
          }
          this.form = Object.assign({}, form)
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      async save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const form = {
              id: this.form.id,
              fileId: this.form.fileId,
              importCountry: this.form.importCountry,
              importHsCode: this.form.importHsCode,
              rateOfDuty: this.form.rateOfDuty,
              certification: this.form.certification,
              riskType: this.form.riskType,
              acceptability: this.form.acceptability,
              surcharge: this.form.surcharge,
            }
            if (this.title == '新增映射明细') {
              const res = await saveCustomerMappingDetails(form)
              if (res.code == '200') {
                this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
                await this.close()
                await this.Modulelist()
              } else {
                this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
              }
            } else if (this.title == '编辑映射明细') {
              const res = await editCustomerMappingDetails(form)
              if (res.code == '200') {
                this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
                await this.close()
                await this.Modulelist()
              } else {
                this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
              }
            }
          }
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  html body .el-tag + .el-tag,
  html body[class*='vab-theme-'] .el-tag + .el-tag {
    margin-left: 0px;
  }
  .tag {
    border: 1px solid #dedede;
    height: 100px;
  }
  .sel-option {
    height: auto;
    max-height: 200px;
    overflow: auto;
    background-color: #fff;
    cursor: pointer;
    font-weight: normal !important;
    padding: 0 5px;
  }
</style>
