<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="660px"
    @close="close"
  >
    <el-form
      ref="form"
      class="editForm"
      label-width="120px"
      :model="form"
      :rules="rules"
    >
      <vab-query-form>
        <vab-query-form-top-panel>
          <el-form-item
            label="客户中文品名:"
            prop="cusProductCnName"
            style="width: 300px"
          >
            <el-input
              v-model.trim="form.cusProductCnName"
              maxlength="200"
              placeholder="请输入"
              show-word-limit
            />
          </el-form-item>
          <el-form-item
            label="客户英文品名:"
            prop="cusProductEnName"
            style="width: 300px"
          >
            <el-input
              v-model.trim="form.cusProductEnName"
              maxlength="200"
              placeholder="请输入"
              show-word-limit
            />
          </el-form-item>
          <el-form-item
            label="申报中文品名:"
            prop="reportCnName"
            style="width: 300px"
          >
            <el-input
              v-model.trim="form.reportCnName"
              maxlength="200"
              placeholder="请输入"
              show-word-limit
            />
          </el-form-item>
          <el-form-item
            label="申报英文品名:"
            prop="reportEnName"
            style="width: 300px"
          >
            <el-input
              v-model.trim="form.reportEnName"
              maxlength="200"
              placeholder="请输入"
              show-word-limit
            />
          </el-form-item>
          <el-form-item
            label="中国海关编码:"
            prop="hsCode"
            style="width: 300px"
          >
            <el-input
              v-model.trim="form.hsCode"
              maxlength="200"
              placeholder="请输入"
              show-word-limit
            />
          </el-form-item>

          <el-form-item
            label="中国申报要素:"
            prop="reportFactor"
            style="width: 300px"
          >
            <el-input
              v-model.trim="form.reportFactor"
              maxlength="200"
              placeholder="请输入"
              show-word-limit
            />
          </el-form-item>
          <el-form-item
            label="中文材质:"
            prop="materialCn"
            style="width: 300px"
          >
            <el-input
              v-model.trim="form.materialCn"
              maxlength="200"
              placeholder="请输入"
              show-word-limit
            />
          </el-form-item>
          <el-form-item
            label="英文材质:"
            prop="materialEn"
            style="width: 300px"
          >
            <el-input
              v-model.trim="form.materialEn"
              maxlength="200"
              placeholder="请输入"
              show-word-limit
            />
          </el-form-item>
          <el-form-item label="用途:" prop="useTo" style="width: 300px">
            <el-input
              v-model.trim="form.useTo"
              maxlength="200"
              placeholder="请输入"
              show-word-limit
            />
          </el-form-item>
          <el-form-item
            label="商品描述:"
            prop="description"
            style="width: 300px"
          >
            <el-input
              v-model.trim="form.description"
              maxlength="500"
              placeholder="请输入"
              show-word-limit
            />
          </el-form-item>
          <el-form-item label="大类:" prop="category" style="width: 300px">
            <el-input
              v-model.trim="form.category"
              maxlength="500"
              placeholder="请输入"
              show-word-limit
            />
          </el-form-item>
          <el-form-item label="章节:" prop="chapter" style="width: 300px">
            <el-input
              v-model.trim="form.chapter"
              maxlength="500"
              placeholder="请输入"
              show-word-limit
            />
          </el-form-item>
          <el-form-item label="商品名称:" prop="items" style="width: 300px">
            <el-input
              v-model.trim="form.items"
              maxlength="500"
              placeholder="请输入"
              show-word-limit
            />
          </el-form-item>
          <el-form-item
            label="监管条件:"
            prop="regulatoryCondition"
            style="width: 300px"
          >
            <el-input
              v-model.trim="form.regulatoryCondition"
              maxlength="500"
              placeholder="请输入"
              show-word-limit
            />
          </el-form-item>
          <el-form-item
            label="检验检疫:"
            prop="inspection"
            style="width: 300px"
          >
            <el-input
              v-model.trim="form.inspection"
              maxlength="500"
              placeholder="请输入"
              show-word-limit
            />
          </el-form-item>
        </vab-query-form-top-panel>
      </vab-query-form>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { draftEdit, saveCustomerMapping } from '@/api/cmsApi'
  export default {
    props: {
      modulelist: Function,
    },
    data() {
      return {
        title: '',
        dialogFormVisible: false,
        form: {
          id: '',
          cusProductCnName: '',
          cusProductEnName: '',
          reportCnName: '',
          reportEnName: '',
          hsCode: '',
          reportFactor: '',
          materialCn: '',
          materialEn: '',
          useTo: '',
          description: '',
          category: '',
          chapter: '',
          items: '',
          regulatoryCondition: '',
          inspection: '',
        },
        rules: {
          cusProductCnName: [
            { required: true, trigger: 'blur', message: '请输入客户中文品名' },
          ],
          cusProductEnName: [
            { required: true, trigger: 'blur', message: '请输入客户英文品名' },
          ],
          reportCnName: [
            { required: true, trigger: 'blur', message: '请输入申报中文品名' },
          ],
          reportEnName: [
            { required: true, trigger: 'blur', message: '请输入申报英文品名' },
          ],
          hsCode: [
            { required: true, trigger: 'blur', message: '请输入中国海关编码' },
          ],
          materialCn: [
            { required: true, trigger: 'blur', message: '请输入中文材质' },
          ],
          materialEn: [
            { required: true, trigger: 'blur', message: '请输入英文材质' },
          ],
        },
      }
    },
    methods: {
      async showEdit(row) {
        if (!row) {
          this.title = '新增客户与关务映射'
        } else {
          this.title = '编辑客户与关务映射'
          const form = {
            id: row.id,
            cusProductCnName: row.cusProductCnName,
            cusProductEnName: row.cusProductEnName,
            reportCnName: row.reportCnName,
            reportEnName: row.reportEnName,
            hsCode: row.hsCode,
            reportFactor: row.reportFactor,
            materialCn: row.materialCn,
            materialEn: row.materialEn,
            useTo: row.useTo,
            description: row.description,
            category: row.category,
            chapter: row.chapter,
            items: row.items,
            regulatoryCondition: row.regulatoryCondition,
            inspection: row.inspection,
          }
          this.form = Object.assign({}, form)
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      async save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            if (this.title == '新增客户与关务映射') {
              const res = await saveCustomerMapping(this.form)
              if (res.code == '200') {
                this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
                await this.close()
                await this.modulelist()
              } else {
                this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
              }
            } else if (this.title == '编辑客户与关务映射') {
              const res = await draftEdit(this.form)
              if (res.code == '200') {
                this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
                await this.close()
                await this.modulelist()
              } else {
                this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
              }
            }
          }
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  html body .el-tag + .el-tag,
  html body[class*='vab-theme-'] .el-tag + .el-tag {
    margin-left: 0px;
  }
  .tag {
    border: 1px solid #dedede;
    height: 100px;
  }
  .sel-option {
    height: auto;
    max-height: 200px;
    overflow: auto;
    background-color: #fff;
    cursor: pointer;
    font-weight: normal !important;
    padding: 0 5px;
  }

  .editForm {
    ::v-deep {
      .el-form-item:first-child {
        margin-bottom: 20px !important;
      }
      .el-form-item + .el-form-item {
        margin-bottom: 20px !important;
      }
    }
  }
</style>
